/* html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #373737;
  overflow: hidden;
  font-family: "Monospaced Number", "Chinese Quote", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
}
html,
body,
div,
a,
i,
button,
select,
option,
optgroup,
hr,
br {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  cursor: default;
} */
.treeview {
  overflow: hidden;
}
.treeview > .toggle {
  pointer-events: all;
  cursor: pointer;
}
.treeview > .toggle:hover {
  color: #63b1de;
  fill: #63b1de;
}

.treeview > span > a > .MuiSvgIcon-root {
  cursor: pointer !important;
}
.dropzone-container{
  min-height: 200px;
  width: 100%;
  border:1px;
  border-color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.dropzone{
  min-height: 150px;
  width:100%;
  border-style: dashed;
  border-color: #63b1de;
  background-color: azure;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}
.dropzone > section{
  display: flex;
  justify-content: center;
}

.display-file{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 2;

}
.image-icon{
  /* height: 100px; */
  width: 150px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.image-icon:hover{
  background-color: #63b1de;
  border-radius: 2px;
  border-width: 2px;
}

.image-icon > img{
  height: 100px;
  width: 100px;
}
.file-container{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.card-body{
  display: flex !important;
  flex-direction: column;
  /* overflow-y: scroll; */
}