.image_container {
  /* position: absolute;
  left: 0;
  top: 0; */
  width: 20vw;
  height: 20vh;
  border-radius: 5px;
}

.image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
