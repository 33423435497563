.errors {
  color: red;
}
.react-datetime-picker__wrapper {
  border: none !important;
}
.react-time-picker__wrapper {
  border: none !important;
}
.link:hover{
  text-decoration: underline;
  cursor: pointer;
}
/* react-animated-tree */
