/* .login{
    position: relative;
    height: 100%;
    width: 100%;
}

.login_form{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
} */
.logins {
  position: absolute;
  top: 50%;
  width: 25%;
  left: 35%;
  transform: translateY(30%);
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 5%;
  /* width: 100%; */
}
.card-header {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
/* .login_form {
  flex: 1;
  justify-content: center;
  align-items: center;
} */
